import React from "react"
import PageHeader from "../components/pageheader"
import PageBody from "../components/pagebody"
import Layout from "../components/layout"
import { Link } from "gatsby"

const RegistrationConfirmedPage = ({ location }) => {
  return (
    <Layout currPage="email_confirmed" location={location}>
      <PageHeader pageTitle="Email confirmed" />
      <PageBody>
        <div className="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>Your email address was successfully confirmed!</h3>
                <p className="content">
                  Click{" "}
                  <Link
                    to="/login"
                    state={{ redirect: location.state?.redirect }}
                  >
                    here
                  </Link>{" "}
                  to proceed to the login screen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </PageBody>
    </Layout>
  )
}

export default RegistrationConfirmedPage
